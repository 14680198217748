import './App.css';

import logotipo from "../src/resources/logo_light.svg";
import './sass/main.scss'


function App() {
  return (
    <div className="App">
      <div class="container">
      <img src={logotipo} alt="Logotipo Mi Fortaleza" className="company-logo"/>
      <p class="msj-loading">Estamos trabajando para ti...</p>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    </div>
  );
}

export default App;
